  
import React, { Component } from 'react'
import '../css/Navbar.css'
import logo from '../image/logo.png'
import { Navbar, Nav} from 'react-bootstrap';
class Navigation extends Component {

  render() {
    return (
      
      <Navbar collapseOnSelect expand='lg' bg='black' variant="dark" className="align">
        <Navbar.Brand href="/home"><img src={logo} alt="Logo" height="47"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav blue" style={{backgroundColor:'dark',border:0}}/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto" >
              <Nav.Link className="text-primary" href='/home'>HOME</Nav.Link>
              <Nav.Link className="text-primary" href='/roadmap'>ROADMAP</Nav.Link>
              <Nav.Link className="text-primary" href='/team'>TEAM</Nav.Link>
              <Nav.Link className="text-primary" href='/about'>ABOUT</Nav.Link>
              <Nav.Link className="text-primary" href='/contact'>CONTACT</Nav.Link>
              <Nav.Link className="text-primary" href='/NFT'>NFT</Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;