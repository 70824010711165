
import React, { Component } from 'react'
import '../css/Team.css'
import team from '../image/team.jpg'
class Team extends Component {


  render() {
    return (
      <div>
        
        <div className="container">
  <div className="row">
    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
      <div className="our-team">
        <div className="picture">
          <img className="img-fluid" src={team} alt="team"/>
        </div>
        <div className="team-content">
          <h3 className="name">0</h3>
          <h4 className="title">01000011. 01000010.</h4>
        </div>
      </div>
    </div>
   
  </div>
</div>


          </div>
          );
  }
}

          export default Team;