import React, { Component } from "react";
import '../css/App.css';
import Navigation from "./Navigation";
import Routes from "../routes/Routes";
class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render() {
    return (
      <div className="App">      
      <Navigation/>      
      <Routes/>
      <footer className="footer">
        <span> &copy; Copyright 2022. All rights reserved</span>
      </footer>
    </div>
    );
  }
}

export default App;