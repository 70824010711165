import React, { Component } from 'react'
import '../css/FileAndPayment.css'
import ReactTooltip from "react-tooltip";
import { Spinner } from 'react-bootstrap';
class FileAndPayment extends Component {

  render() {
    return (
      <div className="file-and-payment">
        
        <div className="file-upload" data-tip data-for="fileTip">
          <div className="image-upload-wrap">
            <input  disabled="true" className="file-upload-input" type='file' onChange="readURL(this);" aria-label="drop file" accept=".fasta or .fna" />
              <ReactTooltip id="fileTip"  effect="float">
                Work in progress <Spinner animation="border" variant="primary" size="sm" />
              </ReactTooltip>
            <div className="drag-text">
              <h3>Select a genome files.</h3>
            </div>
          </div>
        </div>
        <div>
          <h1>Genome as a Service </h1>
          <h4>Our mission is to promote the open genome research. <br/> New world is incoming</h4>
        </div>
    </div>
    );
  }
}

export default FileAndPayment;