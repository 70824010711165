
import React, { Component } from 'react'
import '../css/Roadmap.css'
class Roadmap extends Component {

  componentDidMount= () =>{
    
    window.addEventListener("load", this.callbackFunc);
    // window.addEventListener("resize", this.callbackFunc);
    window.addEventListener("scroll", this.callbackFunc);
}
callbackFunc=()=> {
  var items = document.querySelectorAll(".timeline li");
  for (var i = 0; i < items.length; i++) {
    if (this.isElementInViewport(items[i])) {
      items[i].classList.add("in-view");
    }
  }
 
}
isElementInViewport =(el)=> {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}


  render() {
    return (
      <div>
        <section className="intro">
  <div className="container">
    <h1>ROADMAP &darr;</h1>
  </div>
</section>

<section className="timeline">
  <ul>
    <li>
      <div>
        <time>The Beginning</time> Always remember, when you reach the end of the internet, just one thing can surprise you.<br></br> Make it yourself.
      </div>
    </li>
    <li>
      <div>
        <time>Genome as a Service</time> Make genome visualization available for all. ❌✔️❌
      </div>
    </li>
    <li>
      <div>
        <time>NFT</time>Design & creation NFT ❌ <br></br>NFT sale ❌
      </div>
    </li>
    <li>
      <div>
        <time>Develop a new world</time> Develop Genome Universum  G.U. ❌ <br></br>Beta G.U. ❌ <br></br>Free access to Beta G.U. ❌
      </div>
    </li>
    <li>
      <div>
        <time>GaaS Token</time>The token of G.U. ❌ <br></br>Uniswap listing ❌
      </div>
    </li>
    <li>
      <div>
        <time>GaaS Shop</time>Launch GaaS shop ❌
      </div>
    </li>
  </ul>
</section>



          </div>
          );
  }
}

          export default Roadmap;