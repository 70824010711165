
import React, { Component } from 'react'
import '../css/NFT.css'
import team from '../image/team.jpg'
import ReactTooltip from "react-tooltip";
import { Spinner } from 'react-bootstrap';
class NFT extends Component {

  render() {
    return (

      <div>
        <div className="NFT" style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>

          <a  data-tip data-for="nftTip" href=" " rel="noreferrer"> <img id="first-NFT"
            src={team}
            alt="first NFT"
          /></a>
              <ReactTooltip id="nftTip"  effect="float">
                NFT creation in progress <Spinner animation="border" variant="primary" size="sm" />
              </ReactTooltip>
        </div>
      </div>
    );
  }
}

export default NFT;